import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Contact = () => {

  return (
    <Layout>
      <Seo title="お問い合わせ" />
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeF9Q0Y3qUb0tj6FbrkMHs1-W7VHH5UNBaWSG6mZ5AS60frXQ/viewform?embedded=true" title="google-forms" className="my-3" width="100%" height="1030" frameBorder="0" marginHeight="0" marginWidth="0">読み込んでいます…</iframe>
      <Container className="my-3">
        <div className="mt-5 text-center">
          <Link to="/" className="text-secondary text-decoration-none">記事一覧に戻る</Link>
        </div>
      </Container>
    </Layout>
  )
}

export default Contact;